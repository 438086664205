import React, { Fragment, PureComponent } from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import history from "../../history";
import AlertWrapper from '../Common/AlertWrapper';
import AlertWrapperSuccess from '../Common/AlertWrapperSuccess';
import classnames from 'classnames';
import { AJAX_REQUEST, CURRENCY_FORMAT, CART_TOTAL_CURRENCY_FORMAT, ITEM_COUNT, MEAL_COUNT, BUNDLE_COUNT, COUNT_SUBSCRIPTION, SET_STORAGE, GET_STORAGE, REMOVE_STORAGE, CHECK_STORAGE, NEXT_MONTH, NEXT_WEEK, MEAL_SUB_TOTAL, MEAL_TOTAL, CART_SUB_TOTAL, RECURRING_CART_SUB_TOTAL, COUPON_TOTAL, MEAL_MENU_PUBLIC, AJAX_PUBLIC_REQUEST, BUNDLE_OFFER, BUNDLE_TOTAL, BUNDLE_SUB_TOTAL, BUNDLE_PRODUCT_COUNT, BUNDLE_CART_COUNT, BUNDLE_ITEM_COUNT, BUNDLE_OFFER_NEW} from "../../Constants/AppConstants";
import { NavLink } from 'react-router-dom';
import $ from "jquery";
import CartProductList from './CartProductList';
import CartMealItem from './CartMealItem';
import ShippingSchedule from './Meals/ShippingSchedule';
import { Row, Col } from "react-bootstrap";
import checkoutImage from '../../Assets/images/inter_checkout_btn.png'
import alertIcon from '../../Assets/images/alert-circle.svg'
import BundleProductImage from '../../Assets/images/refer_images/bundle_img.svg'
import DeleteIcon from '../../Assets/images/refer_images/bundle-delete.svg';
import UpArrow from '../../Assets/images/refer_images/bundle_cart_up_arrow.svg';
import productImg from '../../Assets/images/pre-01-1-100x100.png';
import isEmpty from 'lodash/isEmpty';
import ReactImageFallback from "react-image-fallback";

class CartPage extends PureComponent {
    constructor(props) {
        // Check Tax Applicable
        let settings = '';
        if (GET_STORAGE('settings')) {
            settings = JSON.parse(GET_STORAGE('settings'));
        }

        super(props)
        this.state = {
            loading: true,
            taxStatus: settings ? settings.tax_status : 0,
            taxAmount: 0,
            products: [],
            meals: [],
            shippingMethods: [],
            couponList: [],
            coupon_code: "",
            freeShipping: 0,
            cartShippingCost: 0,
            recurringCartShippingCost: 0,
            mealShippingCostStatus: true,

            errors: {},
            isValid: false,
            isLoading: false,
            isFormValid: true,
            server_message: '',
            success_alert_wrapper_show: false,
            coupon_loading: false,
            success_alert_wrapper_show_coupon: false,
            cartItems: [],
            cartDetails: [],
            checkoutIframe: false,
            products_status: false,
            activeIndex: 1,
            bundleProducts: []
        }
        document.title = "Cart-Prestige Labs";
    }
    
    componentDidMount() {
        document.querySelector("body").scrollIntoView();
        this.reApplyCoupon();
        this.getCart();
        if (GET_STORAGE('cart') || GET_STORAGE("Bundle")) {
            this.getAllShippingMethods();
        } else {
            this.setState({ loading: false });
        }
        this.getProductDetails();
    }

    timeOut = (timedata) => {
        setTimeout(function () {
            this.setState({
                success_alert_wrapper_show: false,
                success_alert_wrapper_show_coupon: false,
                isFormValid: true,
                server_message: ""
            });
        }.bind(this), timedata);
    }

    changeHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    getCart = () => {
        if ((ITEM_COUNT() === 0) && (MEAL_COUNT() === 0) && (BUNDLE_ITEM_COUNT() <= 1)) {
            history.push("/");
        }
        CHECK_STORAGE();

        if (GET_STORAGE('cart') && (BUNDLE_CART_COUNT() === 0)) {
            SET_STORAGE("BundleCart", []);
            console.log(GET_STORAGE("cart"), 'reguler_cart');
            this.setState({ products: JSON.parse(GET_STORAGE("cart")) });
            SET_STORAGE("california-warning", 'no');
        } else {
            this.setState({ products: [] });
        }
        if (GET_STORAGE('meals')) {
            this.setState({ meals: JSON.parse(GET_STORAGE('meals')) });
        } else {
            this.setState({ meals: [] });
        }
        if (GET_STORAGE('coupon')) {
            this.setState({ couponList: JSON.parse(GET_STORAGE("coupon")) });
        } else {
            this.setState({ couponList: [] });
        }
        if (GET_STORAGE("Bundle") && (ITEM_COUNT() === 0) && (BUNDLE_ITEM_COUNT() > 1)) {
            console.log(GET_STORAGE("Bundle"), 'bunlde_cart');
            this.setState({ bundleProducts: JSON.parse(GET_STORAGE("Bundle")) });
            SET_STORAGE("BundleCart", JSON.stringify(JSON.parse(GET_STORAGE("Bundle"))));
            if (GET_STORAGE("CustomSubscriptionDetail")) {
                this.setState({ subscription_detail: JSON.parse(GET_STORAGE("CustomSubscriptionDetail")), subscription: GET_STORAGE("is_subscription") ? GET_STORAGE("is_subscription"): 'no' });
            }
        } else {
            this.setState({ bundleProducts: [], subscription: 'no' });
        }
       
    }

    deleteBundle = (e) => {
        e.preventDefault();
        if (window.confirm("Are you sure want to delete item?")) {
            SET_STORAGE("Bundle", []);
            SET_STORAGE("BundleCart", []);
            this.setState({ bundleProducts: []});
            if(BUNDLE_COUNT() === 0){
                history.push("/");
            }
            this.getCart();
        }
    };


    deleteItem = (e, row_id) => {
        e.preventDefault();
        if (window.confirm("Are you sure want to delete item?")) {
            let cart = JSON.parse(GET_STORAGE('cart'));
            let data = {
                cart_product_id: cart[row_id].cart_product_id,
                cart_variation_id: cart[row_id].cart_variation_id,
                subscription: cart[row_id].subscription,
            }
            if (cart.splice(row_id, 1)) {
                SET_STORAGE("cart", JSON.stringify(cart));
                this.removedItemFromCartDb(data);
                this.reApplyCoupon();
                this.getCart();
                this.getProductDetails();
            }
            if((ITEM_COUNT() === 0) && BUNDLE_ITEM_COUNT() <= 1){
                history.push("/");
            }
        }
    }

    removedItemFromCartDb = (data) => {
        AJAX_REQUEST("POST", "cart/removedItem", data).then(results => {
            if (parseInt(results.response.code) === 1000) {
                this.setState({
                    server_message: results.response.message,
                    success_alert_wrapper_show_coupon: false,
                    success_alert_wrapper_show: true
                });
                this.timeOut(5000);
            }
        });
    }

    handleBundleCheckOutClick = () => {
        history.push("/checkout"); 
    }

    updateCart = (e) => {
        e.preventDefault();

        // Item start
        let updateQuantity = [];
        $('input[name^="quantity"]').each(function (key) {
            updateQuantity[key] = $(this).val();
        });

        let cart = [];
        let data = [];
        cart = JSON.parse(GET_STORAGE("cart"));

        if (cart) {
            cart.forEach(function (item, key) {
                item.quantity = updateQuantity[key];
                data.push(item);
                // Save to db
                AJAX_REQUEST("POST", "cart/saveItem", item).then(results => {
                    if (parseInt(results.response.code) !== 1000) {
                        this.setState({ error: results.response.message });
                    }
                });

            });
        }
        SET_STORAGE("cart", JSON.stringify(data));
        // Item end        

        this.setState({
            products: JSON.parse(GET_STORAGE('cart')),
            meals: JSON.parse(GET_STORAGE('meals')),
            coupon: JSON.parse(GET_STORAGE('coupon')),
            server_message: 'Cart Update Successfully',
            success_alert_wrapper_show_coupon: false,
            success_alert_wrapper_show: true
        });

        document.querySelector("body").scrollIntoView();
        this.timeOut(5000);
        this.reApplyCoupon();
        this.getCart();
    }

    getAllShippingMethods = () => {
        AJAX_REQUEST("POST", "order/getShippingMethodList", {}).then(results => {
            if (parseInt(results.response.code) === 1000) {
                let method = results.response.data;
                this.setState({
                    loading: false,
                    shippingMethods: method,
                    cartMethodId: method[0].id,
                    cartShippingCost: method[0].cost,
                    recurringCartMethodId: method[0].id,
                    recurringCartShippingCost: method[0].cost,
                });
                SET_STORAGE("cartMethodId", method[0].id);
                SET_STORAGE("cartID", method[0].id);
                SET_STORAGE("recurringCartMethodId", method[0].id);
                SET_STORAGE("recurringCartId", method[0].id);
            } else {
                this.setState({
                    loading: false,
                    error: results.response.message
                });
            }
        });
    }
    handleCheckOutClick = () => {
        setTimeout(() => {
            this.getProductDetails('Checkout');
        }, 100);
    }

    getProductDetails = (checkout) => {
        let products = [];
        if (GET_STORAGE("cart")) {
            products = JSON.parse(GET_STORAGE("cart"));
        }
        const productIds = products.map(product => product.cart_product_id);
        const data = {
            product_ids: productIds,
        };
        AJAX_PUBLIC_REQUEST("POST", "product/getProductStatus", data).then(results => {
            if (parseInt(results.response.code) === 1000) {
                const products_status = results.response.data;
                const hasProductWithStatusZero = products_status.some(product => product.status === '0');
                this.setState(prevState => ({
                    products_status: hasProductWithStatusZero,
                }), () => {
                    if (checkout == 'Checkout' && !this.state.products_status) {
                        history.push("/checkout");
                    } else if (checkout == 'International-checkout' && !this.state.products_status) {
                        this.setState({
                            checkoutIframe: true,
                        })
                        const body = document.getElementsByTagName('body')[0];
                        body.classList.add('overflow-hidden');
                    }
                });
            } else if (parseInt(results.response.code) === 4004) {
                history.push("/");
            }
        });
    }

    changeCartShippingMethod = (e) => {
        this.setState({
            cartMethodId: e.target.getAttribute('cid'),
            cartShippingCost: e.target.getAttribute('price'),
        })
        SET_STORAGE("cartMethodId", e.target.getAttribute('cid'));
        SET_STORAGE("cartID", e.target.getAttribute('cid'));
        SET_STORAGE("recurringCartMethodId", e.target.getAttribute("cid"));
        SET_STORAGE("recurringCartId", e.target.getAttribute('cid'));
    }
    changeRecurringCartShippingMethod = (e) => {
        this.setState({
            recurringCartMethodId: e.target.getAttribute('rid'),
            recurringCartShippingCost: e.target.getAttribute('price'),
        })
        SET_STORAGE("recurringCartMethodId", e.target.getAttribute('rid'))
    }

    applyCoupon = (e) => {
        e.preventDefault();
        let couponCode = (this.state.coupon_code).trim();

        this.setState({
            isFormValid: true,
            coupon_loading: true,
            success_alert_wrapper_show_coupon: false
        })

        if (couponCode == '' || couponCode == null) {
            this.setState({
                server_message: "The coupon code field is required.",
                isLoading: false,
                coupon_loading: false,
                isFormValid: false,
            });
        } else {
            let couponExists = false;
            let exCouponList = [];
            if (!GET_STORAGE("coupon")) {
                SET_STORAGE("coupon", JSON.stringify(exCouponList));
            }
            exCouponList = JSON.parse(GET_STORAGE("coupon"));

            if (exCouponList.length > 0) {
                exCouponList.forEach(function (exCoupon, key) {
                    if (exCoupon.coupon_code.toUpperCase() === couponCode.toUpperCase()) {
                        couponExists = true;
                    }
                });
            }

            if (!couponExists) {
                let applyCouponCode = [];
                if (exCouponList.length > 0) {
                    exCouponList.forEach(function (couponData, key) {
                        applyCouponCode.push(couponData.coupon_code);
                    });
                }
                applyCouponCode.push(couponCode);

                let data = {
                    coupon_code: applyCouponCode,
                    cart_items: JSON.parse(GET_STORAGE("cart")),
                    meals: JSON.parse(GET_STORAGE("meals")),
                }

                AJAX_REQUEST("POST", "coupon/applyCoupon", data).then(results => {
                    if (parseInt(results.response.code) === 1000) {
                        this.setState({
                            success_alert_wrapper_show_coupon: true,
                            coupon_loading: false,
                            coupon_code: '',
                            server_message: results.response.message,
                        })

                        let couponResponse = results.response.data;
                        if (couponResponse || couponResponse != '') {
                            exCouponList = [];
                            couponResponse.forEach(function (couponData, key) {
                                exCouponList.push(couponData);
                            });
                        }

                        SET_STORAGE("coupon", JSON.stringify(exCouponList));
                        this.getCart();
                    } else {
                        this.setState({
                            server_message: results.response.message,
                            isLoading: false,
                            coupon_loading: false,
                            isFormValid: false,
                            error: results.response.message,
                        });
                    }
                    this.timeOut(5000);
                });
            } else {
                this.setState({
                    server_message: "The coupon code already applied.",
                    isLoading: false,
                    coupon_loading: false,
                    isFormValid: false,
                });
            }
        }
    }

    reApplyCoupon = () => {
        if (GET_STORAGE("coupon")) {
            let exCouponList = JSON.parse(GET_STORAGE("coupon"));
            let coupon_code = [];
            exCouponList.forEach(function (couponData, key) {
                coupon_code.push(couponData.coupon_code);
            });
            let data = {
                coupon_code: coupon_code,
                cart_items: JSON.parse(GET_STORAGE("cart")),
                meals: JSON.parse(GET_STORAGE("meals")),
            }
            AJAX_REQUEST("POST", "coupon/applyCoupon", data).then(results => {
                if (parseInt(results.response.code) === 1000) {
                    SET_STORAGE("coupon", JSON.stringify(results.response.data));
                } else if (parseInt(results.response.code) === 4000) {
                    REMOVE_STORAGE('coupon')
                }
                this.getCart();
            });
        }
    }

    deleteCoupon = (e, row_id) => {
        e.preventDefault();
        if (window.confirm("Are you sure want to delete coupon?")) {
            let coupon = JSON.parse(GET_STORAGE('coupon'));
            if (coupon.splice(row_id, 1)) {
                SET_STORAGE("coupon", JSON.stringify(coupon));
                this.setState({
                    freeShipping: 0,
                    mealShippingCostStatus: true,
                    server_message: 'The coupon code successfully removed.',
                    success_alert_wrapper_show_coupon: false,
                    coupon_code: '',
                    success_alert_wrapper_show: true
                });
                document.querySelector("body").scrollIntoView();
                this.timeOut(5000);
                this.reApplyCoupon();
                this.getCart();
                this.getAllShippingMethods();
            }
        }
    }

    deleteMeal = (e) => {
        e.preventDefault();
        if (window.confirm("Are you sure want to delete meal?")) {
            REMOVE_STORAGE("meals");
            REMOVE_STORAGE('meal_subscription');
            this.setState({
                meals: [],
                server_message: 'Meal Delete Successfully',
                success_alert_wrapper_show_coupon: false,
                success_alert_wrapper_show: true
            });
            this.reApplyCoupon();
            this.getCart();
        }
    }


    // //form ifram
    // modalIframe = () => {
    //     this.setState({
    //         checkoutIframe: true,
    //     })
    // }

    // closeIframe = () => {
    //     this.setState({
    //         checkoutIframe: false
    //     })
    // }

    //accordation 
    handleAccordionClick = (index) => {
        this.setState({ activeIndex: this.state.activeIndex === index ? null : index });
    };

    isSubscription = (e) => {
        if(this.state.subscription == "yes"){
            SET_STORAGE("is_subscription", 'no');
        } else if(this.state.subscription == "no"){
            SET_STORAGE("is_subscription", 'yes');
        }
        this.setState({
            subscription: this.state.subscription == "yes" ? "no" : "yes"
        }, () => {
            BUNDLE_OFFER_NEW();
            const discountDataFromStorage = GET_STORAGE("Discount_data");
            let discount_data_new = discountDataFromStorage ? JSON.parse(discountDataFromStorage) : '';
            this.setState({ discount_data: discount_data_new });
            const bundleDataFromStorage = GET_STORAGE("Bundle");
            
            const products = JSON.parse(bundleDataFromStorage);
            const updateProductsWithDiscount = (products, discount_data) => {
                return products.map((product, index) => {
                    const Inddiscount = discount_data[index];
                    const newSalePrice = product.cart_sale_price - Inddiscount;
                    return {
                        ...product,
                        custom_bundle_discount_price: newSalePrice.toFixed(2),
                        custom_bundle_discount: Inddiscount.toFixed(2)
                    };
                });
            };
            let updatedProducts = products;
            updatedProducts = updateProductsWithDiscount(products, discount_data_new);
            this.setState({ bundleProducts: updatedProducts });
            SET_STORAGE("Bundle", JSON.stringify(updatedProducts));
            SET_STORAGE("BundleCart", JSON.stringify(updatedProducts));
            
            const hasSubscriptionStatusZero = products.some(product => product.subscription_status == "0" || product.subscription_status == null);
            this.setState({ NotallowedtoSubscription: hasSubscriptionStatusZero ? true : false });
            if (GET_STORAGE('is_subscription') == "yes") {
                let bundle = JSON.parse(GET_STORAGE("Bundle"));
                let subscription_detail = JSON.parse(GET_STORAGE("CustomSubscriptionDetail"));
                const subscriptionSavePercentage = parseFloat(subscription_detail.subscription_save_percentage_refer);
                const discountFactor = subscriptionSavePercentage / 100;
        
                updatedProducts = bundle.map(product => {
                    if (product.custom_bundle_discount_price) {
                        const discountedPrice = product.custom_bundle_discount_price * (1 - discountFactor);
                        return {
                            ...product,
                            custom_bundle_discount_price: discountedPrice.toFixed(2)
                        };
                    }
                    return product;
                });
                this.setState({ bundleProducts: updatedProducts });
                SET_STORAGE("Bundle", JSON.stringify(updatedProducts));
                SET_STORAGE("BundleCart", JSON.stringify(updatedProducts));
            }
        });
    };

    render() {

        const {
            errors,
            server_message,
            success_alert_wrapper_show,
            success_alert_wrapper_show_coupon,
            coupon_loading,
            checkoutIframe
        } = this.state;
        const user = this.props.user;
        const errors_data = server_message;
        const hasSubscriptionStatusZero = this.state.bundleProducts.some(product => (product.subscription_status == "0" || product.subscription_status == null));
        let ProductTotalQuantity = 0
        this.state.bundleProducts.map((bundle, key) => {
            ProductTotalQuantity += parseInt(bundle.quantity);
        });


        return (
            <Fragment>
                {(this.state.products.length > 0) && (
                <div className="cart_page_container aff_cart_page_container">
                    <div className="container">
                        <h2 className="font_24 mb_11 text_black text-uppercase fw_el_semi">Shopping CART</h2>
                        <nav aria-label="breadcrumb" className="mb_13">
                            <ol className="breadcrumb cus_breadcrumb pro_breadcrumb cart_breadcrubmb">
                                <li className="breadcrumb-item font_16 choose_text fw_ar_reg">
                                    <NavLink to="/">Home</NavLink>
                                </li>
                                <li className="breadcrumb-item font_16 choose_text fw_ar_reg" aria-current="page">
                                    &gt; Shopping Cart
                                </li>
                            </ol>
                        </nav>
                        <Row className="cart_row">
                            <Col md={8}>
                                <form onSubmit={this.updateCart} id="cartForm" className='w-100'>
                                    <div className="cart_left_det">
                                        <AlertWrapperSuccess errors_data={errors_data} success_alert_wrapper_show={success_alert_wrapper_show} />
                                        <div className="cart_left_det">
                                            {/* Start cart list*/}
                                            {
                                                (this.state.products.length <= 0) ? null :
                                                    this.state.products.map(function (product, key) {
                                                        // Added item delete and reApplyCoupon methos
                                                        product.deleteItem = this.deleteItem;
                                                        product.row_id = key;
                                                        return (
                                                            <CartProductList key={Math.random() + '-' + product.cart_variation_id} product={product} user={user} />
                                                        );
                                                    }.bind(this))
                                            }
                                            {/* End cart list */}
                                            <div className="mt_27 d-flex">
                                                <NavLink className="buy_now fw_ar_reg font_16 text_white d-flex " to={(ITEM_COUNT() > 0) ? '/' : '/meals'}>Continue to Shopping</NavLink>
                                                <input type="submit" className="shop_more take_quiz_btn font_16 text_white fw_ar_reg ml_20 cursor-pointer" name="update" value="Update cart" />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </Col>
                            <Col md={4}>
                                <AlertWrapperSuccess errors_data={errors_data} success_alert_wrapper_show={success_alert_wrapper_show_coupon} />
                                <AlertWrapper errors_data={errors_data} isFormValid={this.state.isFormValid} />
                                <form onSubmit={this.updateCart} id="cartForm" className='w-100'>
                                    <div className="cart_rgt_blk white_bg">
                                        <div className="coupon_blk mb_27">
                                            <span className="d-block text_black font_12 mb_8 fw_ar_reg">Enter Promocode</span>
                                            <div className="d-flex coup_input_blk align-items-center">
                                                <input onChange={this.changeHandler} onBlur={this.changeHandler} value={this.state.coupon_code} type="text" className="coupon_input" name="coupon_code" placeholder="Coupon code" autoComplete="off" />
                                                <input onClick={this.applyCoupon} type="submit" className="coupon_apply" value="Apply" />
                                            </div>
                                        </div>
                                        <ul className="pro_cart_det">
                                            <li className="d-flex align-items-center flex-wrap mb_15 pb_10 subtotal_blk">
                                                <span className="total_span let_spa_2 d-block font_14 fw_ar_reg">Sub Total</span>
                                                <span className="price_span let_spa_2 text-right d-block font_14 fw_ar_reg">{CURRENCY_FORMAT(CART_SUB_TOTAL() + MEAL_SUB_TOTAL())}</span>
                                            </li>
                                            {// Start coupon list
                                                (this.state.couponList.length <= 0) ? "" :
                                                    this.state.couponList.map(function (coupon, key) {

                                                        if (coupon.free_shipping == 1 && coupon.coupon_type == "product") {
                                                            this.setState({
                                                                freeShipping: 1,
                                                                cartShippingCost: 0
                                                            });
                                                        } else if (coupon.free_shipping == 1 && coupon.coupon_type == "meal") {
                                                            this.setState({
                                                                mealShippingCostStatus: false
                                                            });
                                                        } else if (coupon.free_shipping == 1 && coupon.coupon_type == "any") {
                                                            this.setState({
                                                                freeShipping: 1,
                                                                cartShippingCost: 0,
                                                                mealShippingCostStatus: false
                                                            });
                                                        }

                                                        return (
                                                            <Fragment key={key}>
                                                                <li className="d-flex align-items-center flex-wrap mb_7">
                                                                    <span className="total_span let_spa_2 d-block font_14 fw_ar_reg">COUPON: <span className="fw_ar_bold">{coupon.coupon_code}</span></span>
                                                                    <span className="price_span let_spa_2 text-right d-block font_14 fw_ar_reg">{CURRENCY_FORMAT(coupon.discount_amount)}</span>
                                                                    <a onClick={e => this.deleteCoupon(e, key)} href="#" className="w-100 mt_2 red_text font_14 fw_ar_reg" > Remove </a>
                                                                </li>
                                                            </Fragment>
                                                        );
                                                    }.bind(this))
                                                // End coupon list
                                            }
                                            {
                                                (this.state.products.length <= 0) ? '' :
                                                    <Fragment>
                                                        {// Start cart shipping
                                                            (this.state.freeShipping == 0) ?
                                                                <Fragment>
                                                                    <span className=" let_spa_2 d-block font_12 mb_10 fw_ar_bold">Select Shipping Method:</span>
                                                                    {
                                                                        (this.state.shippingMethods.length <= 0) ? null :
                                                                            this.state.shippingMethods.map(function (shipping, key) {
                                                                                if (shipping.allow_for_coupon == 0) {
                                                                                    return (
                                                                                        <Fragment key={key} >
                                                                                            <li className="d-flex align-items-start mb_17">
                                                                                                <label className="d-flex  total_span align-items-start checkbox_label radio_label m-0">
                                                                                                    <input onChange={this.changeHandler} onClick={this.changeCartShippingMethod} price={shipping.cost} cid={shipping.id} defaultChecked={key == 0 ? "checked" : ""} type="radio" name="orderShippingMethod" />
                                                                                                    <span className="checbox mt_1"></span>
                                                                                                    <span className=" let_spa_2 d-block shipping_cost_label font_14 fw_ar_reg pl_6">{shipping.label}</span>
                                                                                                </label>
                                                                                                <label className="price_span checkbox_label radio_label m-0">
                                                                                                    <span className=" let_spa_2 text-right d-block font_14 fw_ar_reg">{CURRENCY_FORMAT(shipping.cost)}</span>
                                                                                                </label>
                                                                                            </li>
                                                                                        </Fragment>
                                                                                    );
                                                                                }
                                                                            }.bind(this))
                                                                    }
                                                                </Fragment>
                                                                :
                                                                <Fragment>
                                                                    {
                                                                        (this.state.shippingMethods.length <= 0) ? null :
                                                                            this.state.shippingMethods.map(function (shipping, key) {
                                                                                if (shipping.allow_for_coupon == 1) {
                                                                                    if (shipping.id == 10) {
                                                                                        // SET_STORAGE("cartMethodId", shipping.id);
                                                                                        return (
                                                                                            <Fragment key={key} >
                                                                                                <li className="d-flex align-items-start mb_17">
                                                                                                    <label className="d-flex  total_span align-items-start checkbox_label radio_label m-0">
                                                                                                        <input onClick={this.changeCartShippingMethod} price={0} cid={shipping.id} defaultChecked={key == 0 ? "checked" : ""} type="radio" name="orderShippingMethod" />
                                                                                                        <span className="checbox mt_1"></span>
                                                                                                        <span className=" let_spa_2 d-block shipping_cost_label font_14 fw_ar_reg pl_6">{shipping.label}</span>
                                                                                                    </label>
                                                                                                    <label className="price_span checkbox_label radio_label m-0">
                                                                                                        <span className=" let_spa_2 text-right d-block font_14 fw_ar_reg">{CURRENCY_FORMAT(0)}</span>
                                                                                                    </label>
                                                                                                </li>
                                                                                                {/* <li className="d-flex align-items-center flex-wrap mb_4">
                                                                                                <span className="total_span let_spa_2 d-block font_14 fw_ar_reg">Shipping Cost</span>
                                                                                                <span className="price_span let_spa_2 text-right d-block font_14 fw_ar_reg">{CURRENCY_FORMAT(0)}</span>
                                                                                            </li>
                                                                                            <li className="d-flex align-items-center flex-wrap mb_3">
                                                                                                <span className="d-block let_spa_2 font_12 fw_ar_reg text_default">{shipping.label}</span>
                                                                                            </li> */}
                                                                                            </Fragment>
                                                                                        );
                                                                                    } else {
                                                                                        if (GET_STORAGE("cartMethodId") == 1) {
                                                                                            this.setState({
                                                                                                cartShippingCost: shipping.cost
                                                                                            });
                                                                                        } else {
                                                                                            this.setState({
                                                                                                cartShippingCost: 0
                                                                                            });
                                                                                        }
                                                                                        // SET_STORAGE("cartMethodId", shipping.id);
                                                                                        return (
                                                                                            <Fragment key={key} >
                                                                                                <li className="d-flex align-items-start mb_17">
                                                                                                    <label className="d-flex  total_span align-items-start checkbox_label radio_label m-0">
                                                                                                        <input onClick={this.changeCartShippingMethod} price={shipping.cost} cid={shipping.id} type="radio" name="orderShippingMethod" />
                                                                                                        <span className="checbox mt_1"></span>
                                                                                                        <span className=" let_spa_2 d-block shipping_cost_label font_14 fw_ar_reg pl_6">{shipping.label}</span>
                                                                                                    </label>
                                                                                                    <label className="price_span checkbox_label radio_label m-0">
                                                                                                        <span className=" let_spa_2 text-right d-block font_14 fw_ar_reg">{CURRENCY_FORMAT(shipping.cost)}</span>
                                                                                                    </label>
                                                                                                </li>
                                                                                            </Fragment>
                                                                                        );
                                                                                    }
                                                                                }
                                                                            }.bind(this))
                                                                    }
                                                                </Fragment>
                                                            // End cart shipping
                                                        }
                                                    </Fragment>
                                            }
                                            {/* {
                                                (this.state.meals != null && this.state.meals != '' && this.state.meals.items.length > 0) ?
                                                    <Fragment>
                                                        <Fragment>
                                                            <li className="d-flex align-items-center flex-wrap mb_15">
                                                                <span className="total_span let_spa_2 d-block font_14 fw_ar_reg">Tax</span>
                                                                <span className="price_span let_spa_2 text-right d-block font_14 fw_ar_reg"> {
                                                                    (this.state.meals.shipping_cost > 0 && this.state.mealShippingCostStatus) ?
                                                                        CURRENCY_FORMAT(this.state.meals.shipping_cost)
                                                                        : "Free Shipping"
                                                                }</span>
                                                            </li>
                                                        </Fragment>
                                                    </Fragment>
                                                    : ''
                                            }
                                            {
                                                (this.state.taxStatus == 0) ? "" :
                                                    <Fragment>
                                                        <li className="d-flex align-items-center flex-wrap mb_15">
                                                            <span className="total_span let_spa_2 d-block font_14 fw_ar_reg">Tax</span>
                                                            <span className="price_span let_spa_2 text-right d-block font_14 fw_ar_reg"> {CURRENCY_FORMAT(this.state.taxAmount)}</span>
                                                        </li>
                                                    </Fragment>
                                            } */}

                                            <li className="d-flex align-items-center flex-wrap mb_26 pt_16 total_blk">
                                                <span className="total_span let_spa_2 d-block font_14 fw_ar_bold">Total</span>
                                                <span className="price_span let_spa_2 text-right d-block font_16 fw_ar_bold">
                                                    {
                                                        (this.state.products.length > 0) ?
                                                            (this.state.meals != null && this.state.meals != '' && this.state.meals.items.length > 0 && this.state.mealShippingCostStatus) ?
                                                                CURRENCY_FORMAT(CART_TOTAL_CURRENCY_FORMAT(Number(CART_SUB_TOTAL()) + Number(MEAL_TOTAL()) + Number(this.state.cartShippingCost) - Number(COUPON_TOTAL())))
                                                                :
                                                                CURRENCY_FORMAT(CART_TOTAL_CURRENCY_FORMAT(Number(CART_SUB_TOTAL()) + Number(MEAL_SUB_TOTAL()) + Number(this.state.cartShippingCost) - Number(COUPON_TOTAL())))
                                                            :
                                                            (this.state.meals != null && this.state.meals != '' && this.state.meals.items.length > 0 && this.state.mealShippingCostStatus) ?
                                                                CURRENCY_FORMAT(CART_TOTAL_CURRENCY_FORMAT(Number(MEAL_TOTAL()) - Number(COUPON_TOTAL())))
                                                                :
                                                                CURRENCY_FORMAT(CART_TOTAL_CURRENCY_FORMAT(Number(MEAL_SUB_TOTAL()) - Number(COUPON_TOTAL())))
                                                    }
                                                </span>
                                            </li>
                                        </ul>

                                        {(this.state.products_status) ?
                                            <div>
                                                <button type="button" disabled={(this.state.products_status) ? "disabled" : ""} className="reorder_btn w-100 d-flex justify-content-center shop_more take_quiz_btn font_16 text_white fw_ar_reg">Check Out</button>
                                                <div className="d-flex align-items-start mt_10">
                                                    <img src={alertIcon} className='mr_5 sezzle_img' alt="alertIcon" />
                                                    <span className=" let_spa_2 d-block font_12 red_text fw_ar_reg" style={{ fontStyle: 'italic' }} >Delete Out of stock product in cart to continue checkout</span>
                                                </div>
                                            </div>
                                            :
                                            <div onClick={this.handleCheckOutClick} className="w-100 d-flex justify-content-center shop_more take_quiz_btn font_16 text_white fw_ar_reg" style={{ cursor: 'pointer' }}>
                                                Check Out
                                            </div>
                                        }
                                        {/* <NavLink to="/checkout" className="w-100 d-flex justify-content-center shop_more take_quiz_btn font_16 text_white fw_ar_reg">
                                            Check Out
                                        </NavLink> */}
                                    </div>
                                </form>
                            </Col>
                        </Row>
                    </div>

                </div>
                )}

                {(this.state.bundleProducts != undefined && this.state.bundleProducts.length > 0) && (
                <div className="cart_page_container">
                    <form id="cartForm" className="w-100">
                        <div className="container">
                            <h2 className="font_24 mb_11 text_black text-uppercase fw_el_semi">SHOPPING CART</h2>
                            <nav aria-label="breadcrumb" className="mb_13">
                                <ol className="breadcrumb cus_breadcrumb pro_breadcrumb cart_breadcrubmb">
                                    <li className="breadcrumb-item font_16 choose_text fw_ar_reg">
                                        <NavLink to="/">Home</NavLink>
                                    </li>
                                    <li className="breadcrumb-item font_16 choose_text fw_ar_reg" aria-current="page">
                                        &gt; Shopping Cart
                                    </li>
                                </ol>
                            </nav>
                            <Row className="cart_row">
                                <Col md={8}>
                                    <div className="cart_left_det">
                                        <AlertWrapperSuccess
                                            errors_data={errors_data}
                                            success_alert_wrapper_show={success_alert_wrapper_show}
                                        />
                                        <div className="cart_accordation">
                                            <div className="card_header d-flex align-items-start justify-content-between">
                                                <div className="card_head_left d-flex align-items-center">
                                                    <div className="content pl_5">
                                                        <h3 className="font_16 text_black mb_6 fw-bold">Product Bundle</h3>
                                                        <div className="d-flex align-items-center mb_10">
                                                            <span className="d-block font_12 text_green pr_6">{BUNDLE_OFFER()}% OFF</span>
                                                            <span className="d-block segregation"></span>
                                                            <span className="d-block font_12 text_opacity pl_6">{ProductTotalQuantity} Items</span>
                                                        </div>
                                                        {(this.state.subscription_detail.is_subscription == 'no') ? null : (
                                                            <label onClick={!hasSubscriptionStatusZero ? this.isSubscription : null} className={`w-100 cus_bun_subscri_blk subscri_blk pro_subscri_blk d-flex align-items-center checkbox_label mx-0 mb_22 radio_label ${hasSubscriptionStatusZero ? 'disable disabled' : ''}`} htmlFor="purchase_type_subscription" style={hasSubscriptionStatusZero ? { opacity: '0.5', cursor: 'not-allowed' } : {}}>
                                                                <div className="sub_lft position-relative">
                                                                    <input defaultValue="subscription" name="purchase_type" id="purchase_type_subscription" className= {`${hasSubscriptionStatusZero ? 'disable disabled' : ''}`} type="radio"
                                                                        checked={this.state.subscription === "yes"} onChange={this.isSubscription} disabled={hasSubscriptionStatusZero}/>
                                                                    <span className="checbox"></span>
                                                                </div>
                                                                <div className="sub_rgt pl_16">
                                                                    <div className="d-flex align-items-center">
                                                                        <h3 className="font_16 text_black let_spa_2 fw_ar_reg m-0">
                                                                            Subscribe and Save {this.state.subscription_detail.subscription_save_percentage || 0}% for 1 month
                                                                        </h3>
                                                                    </div>
                                                                    {this.state.subscription !== 'no' && (
                                                                        <div className="d-flex align-items-center mt_5">
                                                                            <span className="d-block font_14 text_black fw_ar_bold let_spa_2">
                                                                                Order will ship every: 1 Month(s)
                                                                            </span>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </label>
                                                        )}
                                                        <NavLink  className="buy_now fw_ar_reg font_12 text_black d-flex modify_btn bundle_modify_button" to = "/custom-bundle">Modify</NavLink>
                                                        {/* <button className="buy_now fw_ar_reg font_12 text_black d-flex modify_btn">Modify</button> */}
                                                    </div>
                                                </div>
                                                <div className="cart_head_rgt">
                                                    <div className="d-flex align-items-center justify-content-end mb_30">
                                                    <a onClick={(e) => this.deleteBundle(e)} href="#" className="mr_30"><img src={DeleteIcon} /></a>
                                                        {(this.state.activeIndex === 1) ?
                                                            <img src={UpArrow} alt="Up Arrow icon" className="cursor-pointer" onClick={() => this.handleAccordionClick(0)} /> :
                                                            <img src={UpArrow} alt="Up Arrow icon" className="cursor-pointer img_rotate" onClick={() => this.handleAccordionClick(1)} />
                                                        }
                                                    </div>
                                                    <div className="d-flex align-items-center justify-content-end">
                                                        <h4 className="font_14 text_opacity mb_0 mr_30 line_through fw-bold">{CURRENCY_FORMAT(BUNDLE_TOTAL())}</h4>
                                                        <h4 className="font_14 text_black mb_0 fw-bold">{CURRENCY_FORMAT(BUNDLE_SUB_TOTAL())}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                            {(this.state.bundleProducts.length > 0 && this.state.activeIndex == 1) && (
                                            <div className="card_body">
                                                <div className="card_body_cart_list">
                                                    {this.state.bundleProducts.map((product, index) => (
                                                        <div className="cart_pro_list d-flex align-items-center justify-content-between" key={index}>
                                                            <div className="cart_pro_list_left w-100 p-0 d-flex align-items-center">
                                                                <div className="cart_pro_img">
                                                                <ReactImageFallback
                                                                    src={product.cart_image}
                                                                    fallbackImage={require('../../Assets/images/preloader.gif')}
                                                                    initialImage={require('../../Assets/images/preloader.gif')}
                                                                    alt=''
                                                                    className="cart_product_img" />
                                                                </div>
                                                                <div className="cart_pro_det pl_13">
                                                                    <div className='d-flex align-items-start justify-content-between head_del_blk mb_5'>
                                                                        <h4 className="font_16 text_black let_spa_2 fw_el_bold m-0 line_height_25">{product.cart_product_name}</h4>
                                                                    </div>
                                                                    {/* <span className="d-block items_text font_14 fw_ar_reg mb_9">{product.cart_variation_name}</span> */}
                                                                    {product.cart_variation_name !== '1 month' && (
                                                                        <span className="d-block items_text font_14 fw_ar_reg mb_9">
                                                                            {product.cart_variation_name}
                                                                        </span>
                                                                    )}
                                                                    <div className='d-flex align-items-start justify-content-between'>
                                                                        <div>
                                                                            <span className='font_16 text_black fw-bold'>{product.quantity}</span><span className='text_opacity font_12 pl_3'>Qty</span>
                                                                        </div>
                                                                       
                                                                        <div className='d-flex align-items-start justify-content-between'>
                                                                            <h4 className="mr_30 font_14 text_opacity mb_0 line_through fw-bold justify-content-end">{CURRENCY_FORMAT(product.cart_sale_price * product.quantity)}</h4>
                                                                            <h4 className="font_14 m-0 text_black let_spa_2 text-uppercase fw_ar_bold" data-title="total">
                                                                            {
                                                                                (product.subscription == "yes") ?
                                                                                    CURRENCY_FORMAT(product.cart_discount_price * product.quantity)
                                                                                    :
                                                                                    CURRENCY_FORMAT(product.custom_bundle_discount_price * product.quantity)
                                                                            }</h4>
                                                                        </div>
                                                                    </div>
                                                                    <div className='d-flex align-items-start justify-content-between mb_9'>
                                                                        {(this.state.subscription_detail.is_subscription == 'yes' && (product.subscription_status == '0' || product.subscription_status == null)) && (
                                                                            <span className="d-block items_text font_14 fw_ar_reg mb_9 text_red">
                                                                                {product.cart_variation_name != null
                                                                                    ? `${product.cart_product_name} - ${product.cart_variation_name} is not available for subscription. Please remove from your cart to enable subscribe and save.`
                                                                                    : `${product.cart_product_name} is not available for subscription. Please remove from your cart to enable subscribe and save.`}
                                                                            </span>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        )}
                                        </div>
                                        <div className="mt_27 d-flex align-items-center">
                                            <NavLink className="buy_now fw_ar_reg font_16 text_white d-flex" to="/custom-bundle">Continue to Shopping</NavLink>
                                        </div>
                                    </div>
                                </Col>

                                <Col md={4}>
                                    <AlertWrapperSuccess errors_data={errors_data} success_alert_wrapper_show={success_alert_wrapper_show_coupon} />
                                    <AlertWrapper errors_data={errors_data} isFormValid={this.state.isFormValid} />
                                    <div className="cart_rgt_blk">
                                        <ul className="pro_cart_det">
                                            <li className="d-flex align-items-center flex-wrap mb_15 pb_10 subtotal_blk">
                                                <span className="total_span let_spa_2 d-block font_14 fw_ar_reg">Sub Total</span>
                                                <span className="price_span let_spa_2 text-right d-block font_14 fw_ar_reg">{CURRENCY_FORMAT(BUNDLE_SUB_TOTAL() + MEAL_SUB_TOTAL())}</span>
                                            </li>
                                            {(this.state.bundleProducts.length > 0) ? 
                                                <Fragment>
                                                    {// Start cart shipping
                                                        (this.state.freeShipping == 0) ?
                                                            <Fragment>
                                                                <span className=" let_spa_2 d-block font_12 mb_10 fw_ar_bold">Select Shipping Method</span>
                                                                {
                                                                    (this.state.shippingMethods.length <= 0) ? null :
                                                                        this.state.shippingMethods.map(function (shipping, key) {
                                                                            if (shipping.allow_for_coupon == 0) {
                                                                                return (
                                                                                    <Fragment key={key} >
                                                                                        <li className="d-flex align-items-start mb_17">
                                                                                            <label className="d-flex  total_span align-items-start checkbox_label radio_label m-0">
                                                                                                <input onChange={this.changeHandler} onClick={this.changeCartShippingMethod} price={shipping.cost} cid={shipping.id} defaultChecked={key==0 ? "checked" : ""} type="radio" name="orderShippingMethod" />
                                                                                                <span className="checbox mt_1"></span>
                                                                                                <span className=" let_spa_2 d-block shipping_cost_label font_14 fw_ar_reg pl_6">{shipping.label}</span>
                                                                                            </label>
                                                                                            <label className="price_span checkbox_label radio_label m-0">
                                                                                                <span className=" let_spa_2 text-right d-block font_14 fw_ar_reg">{CURRENCY_FORMAT(shipping.cost)}</span>
                                                                                            </label>
                                                                                        </li>
                                                                                    </Fragment>
                                                                                );
                                                                            }
                                                                        }.bind(this))
                                                                }
                                                            </Fragment>
                                                            :
                                                            <Fragment>
                                                                {
                                                                    (this.state.shippingMethods.length <= 0) ? null :
                                                                        this.state.shippingMethods.map(function (shipping, key) {
                                                                            if (shipping.allow_for_coupon == 1) {
                                                                                if (shipping.id == 10) {
                                                                                // SET_STORAGE("cartMethodId", shipping.id);
                                                                                return (
                                                                                    <Fragment key={key} >
                                                                                        <li className="d-flex align-items-start mb_17">
                                                                                            <label className="d-flex  total_span align-items-start checkbox_label radio_label m-0">
                                                                                                <input  onClick={this.changeCartShippingMethod} price={0} cid={shipping.id} defaultChecked={key == 0 ? "checked": ""} type="radio" name="orderShippingMethod" />
                                                                                                <span className="checbox mt_1"></span>
                                                                                                <span className=" let_spa_2 d-block shipping_cost_label font_14 fw_ar_reg pl_6">{shipping.label}</span>
                                                                                            </label>
                                                                                            <label className="price_span checkbox_label radio_label m-0">
                                                                                                <span className=" let_spa_2 text-right d-block font_14 fw_ar_reg">{CURRENCY_FORMAT(0)}</span>
                                                                                            </label>
                                                                                        </li>
                                                                                        {/* <li className="d-flex align-items-center flex-wrap mb_4">
                                                                                            <span className="total_span let_spa_2 d-block font_14 fw_ar_reg">Shipping Cost</span>
                                                                                            <span className="price_span let_spa_2 text-right d-block font_14 fw_ar_reg">{CURRENCY_FORMAT(0)}</span>
                                                                                        </li>
                                                                                        <li className="d-flex align-items-center flex-wrap mb_3">
                                                                                            <span className="d-block let_spa_2 font_12 fw_ar_reg text_default">{shipping.label}</span>
                                                                                        </li> */}
                                                                                    </Fragment>
                                                                                );
                                                                            } else {
                                                                                if (GET_STORAGE("cartMethodId") == 1) {
                                                                                    this.setState({
                                                                                        cartShippingCost: shipping.cost
                                                                                    });
                                                                                } else {
                                                                                    this.setState({
                                                                                        cartShippingCost: 0
                                                                                    });
                                                                                }
                                                                                return (
                                                                                    <Fragment key={key} >
                                                                                        <li className="d-flex align-items-start mb_17">
                                                                                            <label className="d-flex  total_span align-items-start checkbox_label radio_label m-0">
                                                                                                <input  onClick={this.changeCartShippingMethod} price={shipping.cost} cid={shipping.id} type="radio" name="orderShippingMethod" />
                                                                                                <span className="checbox mt_1"></span>
                                                                                                <span className=" let_spa_2 d-block shipping_cost_label font_14 fw_ar_reg pl_6">{shipping.label}</span>
                                                                                            </label>
                                                                                            <label className="price_span checkbox_label radio_label m-0">
                                                                                                <span className=" let_spa_2 text-right d-block font_14 fw_ar_reg">{CURRENCY_FORMAT(shipping.cost)}</span>
                                                                                            </label>
                                                                                        </li>
                                                                                    </Fragment>
                                                                                );
                                                                            }
                                                                        }
                                                                    }.bind(this))
                                                                }
                                                            </Fragment>
                                                        // End cart shipping
                                                    }
                                                </Fragment>
                                                : ''
                                            }
                                            
                                            {/* <li className="d-flex align-items-center flex-wrap mb_15">
                                                <span className="total_span let_spa_2 d-block font_14 fw_ar_reg">Tax</span>
                                                <span className="price_span let_spa_2 text-right d-block font_14 fw_ar_reg">$0.00</span>
                                            </li> */}
                                            <li className="d-flex align-items-center flex-wrap mb_26 pt_16 total_blk">
                                                <span className="total_span let_spa_2 d-block font_14 fw_ar_bold">Total</span>
                                                <span className="price_span let_spa_2 text-right d-block font_16 fw_ar_bold">{
                                                    (this.state.bundleProducts.length > 0) ?
                                                    CURRENCY_FORMAT(CART_TOTAL_CURRENCY_FORMAT(Number(BUNDLE_SUB_TOTAL()) + Number(MEAL_TOTAL()) + Number(this.state.cartShippingCost) - Number(COUPON_TOTAL())))
                                                    : '$0.00 USD'
                                                }</span>
                                            </li>
                                        </ul>
                                        <div onClick={this.handleBundleCheckOutClick} className="w-100 d-flex justify-content-center shop_more take_quiz_btn font_16 text_white fw_ar_reg" style={{ cursor: 'pointer' }}>
                                            Check Out
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </form>
                </div>
                )}
            </Fragment>
        );
    }
}

CartPage.propTypes = {
    auth: PropTypes.object.isRequired,
    //meals: PropTypes.array.isRequired
};

function mapStateToProps(state) {
    return {
        auth: state.auth,
        meals: state.meals
    };
}

const mapDispachToProps = dispach => {
    return {
        addToCart: () => dispach({ type: "ADD_TO_CART", value: JSON.parse(GET_STORAGE("cart")) }),
        addMealToCart: () => dispach({ type: "ADD_MEAL_TO_CART", value: JSON.parse(GET_STORAGE("meals")) })
    };
};

export default connect(mapStateToProps, mapDispachToProps)(CartPage);
