import React, { Fragment, PureComponent } from "react";
import AddToCartButton from "./AddToCartButton";
import { CURRENCY_FORMAT, GET_STORAGE } from "../../Constants/AppConstants";
import Parser from 'html-react-parser';
import history from "../../history";
import ReactImageFallback from "react-image-fallback";
import $ from "jquery";
import { Row, Col } from "react-bootstrap";
import SubDropdown from '../../Assets/images/affiliate_imges/sub_dropdown.svg'

class ProductsList extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            subscription: "no",
            modalId: "disclaimerModal" + this.props.product.product_id,

        }
    }

    showHideProductDetails = () => {
        this.setState(state => ({
            isToggleOn: !state.isToggleOn
        }));
    }

    componentDidMount() {
        let product = this.props.product;
        document.querySelector("body").scrollIntoView();
        this.changeVariation();

        if (this.props.product.hasOwnProperty('months') && this.props.product.months.length > 0) {
            this.changeVariation(this.props.product.months[0].id);
        }
        if (this.state.quantity) {
            this.setState({ quantity: parseInt(this.state.quantity) })
        } else {
            this.setState({ quantity: parseInt(product.quantity) })
        }
    }

    // alertModel = (e) => {
    //     if(GET_STORAGE("cart")){
    //         $(`#${'alertModel'}`).modal({
    //             backdrop: 'static',
    //             keyboard: false,
    //         });
    //     } else{
    //         history.push("/cart");
    //     }
    // }

    handleCart = () => {
        history.push({
            pathname: "/cart",
            state: { productType: 'regular_product' }
        });
    }

    changeVariation = (monthId = null) => {
        const product = this.props.product;
        if (monthId == null) {
            const variation = product.hasOwnProperty("variations") ? product.variations[product.first_month] : null;

            let firstRegularPriceDisplay = false;
            let regular_price = 0;
            let sale_price = 0;
            if (variation && parseFloat(variation.sale_price) > 0) {
                firstRegularPriceDisplay = true;
                regular_price = variation.regular_price;
                sale_price = variation.sale_price;
            } else {
                regular_price = variation ? variation.regular_price : 0;
                sale_price = variation ? variation.regular_price : 0;
            }

            this.setState({
                firstMonthVariationId: (variation && variation.hasOwnProperty('variation_id')) ? variation.variation_id : null,
                firstRegularPrice: CURRENCY_FORMAT(regular_price),
                firstSalePrice: CURRENCY_FORMAT(sale_price),
                firstRegularPriceDisplay: firstRegularPriceDisplay,
                // AddToCart State for Cart
                itemCount: product.item_count,
                cartProductId: product.product_id,
                cartProductName: product.title,
                cartProductDescription: product.short_description,
                cartProductSKU: variation?.sku,
                cartProductIsBundle: product.is_bundle,
                cartImage: product.thumb_image,
                cartFirstVariationId: variation ? variation.variation_id : '',
                cartFirstVariationName: variation ? variation.variation_name : '',
                cartFirstSalePrice: sale_price,
                cartFirstQuantity: variation ? variation.quantity : 0,
                subscription: "no",
                cartSubscriptionMsg: "Every 1 Month(s)",
                cartDiscountPrice: ((sale_price - (sale_price * product.subscription_save_percentage) / 100)),
                warehouse: variation.warehouse,
            });
        } else {
            const variation = product.hasOwnProperty("variations") ? product.variations[monthId] : null;

            let regularPriceDisplay = false;
            let regular_price = 0;
            let sale_price = 0;
            if (parseFloat(variation.sale_price) > 0) {
                regularPriceDisplay = true;
                regular_price = variation.regular_price;
                sale_price = variation.sale_price;
            } else {
                regular_price = variation.regular_price;
                sale_price = variation.regular_price;
            }

            this.setState({
                variationId: variation.hasOwnProperty("variation_id") ? variation.variation_id : null,
                regularPrice: CURRENCY_FORMAT(regular_price),
                salePrice: CURRENCY_FORMAT(sale_price),
                regularPriceDisplay: regularPriceDisplay,
                // AddToCart State for Cart
                itemCount: product.item_count,
                cartProductId: product.product_id,
                cartProductName: product.title,
                cartProductDescription: product.short_description,
                cartProductSKU: variation.sku,
                cartProductIsBundle: product.is_bundle,
                cartImage: product.thumb_image,
                cartVariationId: variation.variation_id,
                cartVariationName: variation.variation_name,
                cartSalePrice: sale_price,
                cartQuantity: variation.quantity,
                warehouse: variation.warehouse,
            });
        }
    }

    changeMonth = (e) => {
        this.changeVariation(e.target.value);
    }

    isSubscription = (e) => {

        if (e.target.value == 'yes') {
            this.setState({ subscription: "no" });
        } else {
            $(`#${this.state.modalId}`).modal({
                backdrop: 'static',
                keyboard: false,
            });
        }

        // if (this.state.subscription === "yes") {
        //     this.setState({ subscription: "no" });
        // } else {
        //     this.setState({ subscription: "yes" });
        // }

    }

    quantityIncrement = (e) => {
        this.setState({ quantity: parseInt(Number(this.state.quantity) + 1) })
    }

    quantityDecrement = (e) => {
        this.setState({ quantity: parseInt(Number(this.state.quantity) - 1) });
    }

    changeHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    render() {
        let product = this.props.product;
        // const product = this.props.product;

        return (
            <Fragment>
                <div className="order_pro_list white_bg">
                    <Row>
                        <Col md={12}>
                            <div className="d-flex order_pro_left_det">
                                <div className="order_pro_img">
                                    <ReactImageFallback
                                        src={product.hasOwnProperty('list_image') ? product.list_image : null}
                                        fallbackImage={require('../../Assets/images/preloader.gif')}
                                        initialImage={require('../../Assets/images/preloader.gif')}
                                        alt={product.hasOwnProperty('title') ? product.title : null}
                                        className="" />
                                </div>
                                <div className="order_pro_det pl_16">
                                    <h2 className="text_black d-flex m-0 font_20 let_spa_2 fw_el_bold"> {product.hasOwnProperty('title') ? product.title : null} </h2>
                                    <h3 className="text_black d-flex mb_10 font_16 let_spa_2 fw_ar_reg pro_des_aff">{product.hasOwnProperty('short_description') ? Parser(product.short_description) : null}</h3>
                                    <div className="row m-0">
                                        <div className="col-md-8 p-0">
                                            <div className="d-flex align-items-center">

                                                {
                                                    (product.subscription == "yes") ?
                                                        <Fragment>
                                                            <label className="subscri_blk mr_19 d-flex align-items-center checkbox_label mb_0 radio_label" htmlFor="">
                                                                <div className="sub_lft position-relative">
                                                                    <input onChange={this.isSubscription} value={this.state.subscription} type="checkbox" name="" checked={(this.state.subscription == 'yes') ? true : false} className="form-check-input" id="" />
                                                                    <span className="checbox"></span>
                                                                </div>
                                                                <div className="sub_rgt pl_16">
                                                                    <div className="d-flex align-items-center mb_5">
                                                                        <h3 className="font_16 text_black let_spa_2 fw_ar_reg m-0">Subscribe and Save {product.hasOwnProperty('subscription_save_percentage') ? product.subscription_save_percentage : null} %</h3>
                                                                    </div>
                                                                    <div className="d-flex align-items-center">
                                                                        <span className="d-block font_14 text_black fw_ar_bold let_spa_2">{CURRENCY_FORMAT(this.state.cartDiscountPrice)}</span>
                                                                        {/* <span className="d-block font_14 text_black fw_ar_bold let_spa_2 pl_6">$139.00</span> */}
                                                                    </div>
                                                                </div>
                                                            </label>
                                                        </Fragment>
                                                        : null
                                                }
                                            </div>
                                        </div>
                                        <div className="col-md-4 p-0 pro_qty_cat_blk">
                                            <Col md={12} className="p-0 order_pro_btn d-flex flex-column align-items-end justify-content-between">
                                                <div className="d-flex align-items-center justify-content-end mb_15">
                                                    {
                                                        (this.state.firstRegularPrice != '$0.00 USD') ?
                                                            <span className="font_16 d-block items_text let_spa_2 fw_ar_reg line_through">{this.state.firstRegularPrice}</span>
                                                            : ""
                                                    }
                                                    {(this.state.subscription == 'no') ?
                                                        <span className='font_16 text_black fw_ar_reg d-block pl_10'>{this.state.firstSalePrice}</span>
                                                        : <span className='font_16 text_black fw_ar_reg d-block pl_10'>{CURRENCY_FORMAT(this.state.cartDiscountPrice)}</span>
                                                    }
                                                </div>
                                                <div className="quan_cart_blk d-flex w-100 justify-content-end">
                                                    <AddToCartButton firstMonth="yes" cl cartState={this.state} updateError={this.props.updateError} />
                                                </div>
                                            </Col>
                                        </div>
                                    </div>
                                    {
                                        (product.months.length <= 0) ?
                                            ""
                                            :
                                            <Fragment>
                                                <div className="row m-0 subscribtion_mont_row">
                                                    <div className="col-md-8 p-0">
                                                        <div className="d-flex align-items-center">

                                                            {
                                                                // (product.subscription == "yes") ?
                                                                    <Fragment>
                                                                        <label className="subscri_blk mr_19 d-flex align-items-center checkbox_label mb_0 radio_label" htmlFor="">
                                                                            {/* <div className="sub_lft position-relative">
                                                                                <input onChange={this.isSubscription} value={this.state.subscription} type="checkbox" name="" checked={(this.state.subscription == 'yes') ? true : false} className="form-check-input" id="" />
                                                                                <span className="checbox"></span>
                                                                            </div> */}
                                                                            <div className="sub_rgt w-100">
                                                                                <div className="subscri_blk choose_fla_blk p-0">
                                                                                    <h3 className="font_16 text_black let_spa_2 fw_ar_reg m-0 ">Choose Month</h3>
                                                                                    <select onChange={this.changeMonth} name="month" className="w-100 select_flavour font_16 let_spa_2 text-black fw_ar_bold">
                                                                                        {
                                                                                            (product.months.length <= 0) ? null :
                                                                                                product.months.map(function (month, key) {
                                                                                                    return (
                                                                                                        <option key={key} value={month.hasOwnProperty('id') ? month.id : null}>{month.hasOwnProperty('value') ? month.value : null}</option>
                                                                                                    )
                                                                                                }.bind(this))
                                                                                        }
                                                                                    </select>
                                                                                </div>
                                                                                {/* <div className="d-flex align-items-center">
                                                                                    <span className="d-block font_14 text_black fw_ar_bold let_spa_2">{CURRENCY_FORMAT(this.state.cartDiscountPrice)}</span> */}
                                                                                    {/* <span className="d-block font_14 text_black fw_ar_bold let_spa_2 pl_6">$139.00</span> */}
                                                                                {/* </div> */}
                                                                            </div>
                                                                        </label>
                                                                    </Fragment>
                                                                    // : null
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 p-0 pro_qty_cat_blk">
                                                        <Col md={12} className="p-0 order_pro_btn d-flex flex-column align-items-end justify-content-between">
                                                            <div className="d-flex align-items-center justify-content-end mb_15">
                                                                {
                                                                    (this.state.regularPrice != '$0.00 USD') ?
                                                                        <span className="font_16 d-block items_text let_spa_2 fw_ar_reg line_through"> {this.state.regularPrice}</span>
                                                                        : ""
                                                                }
                                                                <span className="font_16 d-block text_black let_spa_2 fw_ar_reg pl_16">{this.state.salePrice}</span>
                                                            </div>
                                                            <div className="quan_cart_blk d-flex w-100 justify-content-end">
                                                                <AddToCartButton cartState={this.state} updateError={this.props.updateError} />
                                                            </div>
                                                        </Col>
                                                    </div>
                                                </div>
                                            </Fragment>
                                    }


                                </div>
                            </div>
                        </Col>

                    </Row>

                </div>


                {/* subscription disclaimer modal start  */}
                <div className="modal fade" id={this.state.modalId} tabIndex="-1" role="dialog" aria-labelledby="disclaimerModal" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header cus-modal-header">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body montserrat">
                                <p className="modal-body-content">
                                    By selecting Subscribe and Save I understand that I can cancel within 14 days of my next renewal date by calling customer support at 1-800-470-7560.
                                </p>
                            </div>
                            <div className="modal-footer">
                                <button onClick={(e) => this.setState({ subscription: 'yes' })} type="button" className="cus_button" data-dismiss="modal">I Agree</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* subscription disclaimer modal end  */}
                {/* <div className="modal fade" id={'alertModel'} tabIndex="-1" role="dialog" aria-labelledby="alertModel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header cus-modal-header">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body montserrat">
                                <p className="font_16 text_black fw_ar_reg m-0">
                                    You cannot add a custom bundle product to your cart while a regular product is already in it, You can delete custom bundle product to proceed with the regular product.
                                </p>
                            </div>
                            <div className="modal-footer">
                                <button onClick={(e) => this.handleCart()} type="button" className="cus_button" data-dismiss="modal">OK</button>
                            </div>
                        </div>
                    </div>
                </div> */}
            </Fragment >
        );
    }
}

export default ProductsList;
